<template>
    <div class="shop-address">
        <Header backType="blank" :back="true"></Header>
        <div class="container" id="container"></div>
    </div>
</template>
<script>
export default {
    mounted() {
        this.initMap();
    },
    methods: {
        initMap() {
            let _this = this;
            let center = [this.$route.query.X, this.$route.query.Y];
            /* global AMap */
            let map = new AMap.Map("container", {
                resizeEnable: true,
                zoom: 16,
                center: center,
                mapStyle: "amap://styles/whitesmoke"
            });
            let infoWindow = new AMap.InfoWindow({
                isCustom: true,
                offset: new AMap.Pixel(5, -35),
                content: `<div class="map-destination">${_this.$route.query.N}</div>` //使用默认信息窗体框样式，显示信息内容
            });

            infoWindow.open(map, map.getCenter());
            new AMap.Marker({
                map: map,
                position: center
            });
        }
    }
};
</script>
<style lang="scss">
.shop-address {
    .container {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
    }
    .map-destination {
        background-color: #fff;
        padding: 5px;
        border-radius: 5px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
</style>